import { useState, useEffect, useRef } from "react";

const ArticleFeature = ({
  feature,
  handleFeatureChange,
  checked,
  isMobile,
  isSmallWindow,
  handleShowDescription,
  handleSetFeatureOffset,
}) => {
  const [showDescription, setShowDescription] = useState([false, false]);
  let featureName, featureClass;
  const elementRef = useRef(null);
  let xOffset;

  // get offset of elementFeature to calculate position of description
  const sendOffsetValue = () => {
    if (elementRef.current) {
      xOffset = elementRef.current?.offsetLeft;
      if (isSmallWindow) {
        xOffset = "";
      }
      handleSetFeatureOffset(xOffset);
    }
  };

  // might remove it
  if (feature === "cut") {
    featureName = "Skrati";
    featureClass = "articleFeature__control--cut";
  }
  if (feature === "list") {
    featureName = "Lista";
    featureClass = "articleFeature__control--list";
  }

  return (
    <section
      ref={elementRef}
      className="websiteTitle__articleFeature"
      onMouseEnter={() => sendOffsetValue()}
    >
      <section
        className={featureClass}
        onMouseEnter={() => handleShowDescription(feature, true)}
        onMouseLeave={() => handleShowDescription(feature, false)}
      >
        <span className="articleFeature__icon">{featureName} </span>
        <input
          className="articleFeature__checkbox"
          type="checkbox"
          checked={checked}
          onChange={() => handleFeatureChange(feature)}
        />
      </section>
    </section>
  );
};

export default ArticleFeature;
